































































































































































































































































































































































































































 .item-width  {
     width: 48%;
     height: 35px;
 }
 .divCss{
     border:2px solid #fff;
     width: 100px ;
     height:100px;
 }
 .divCss:hover{
     border:2px solid #5cadee;
     width: 100px ;
     height:100px;
     cursor:pointer;
 }
.pub_dialog {
     display: flex;
     justify-content: center;
     align-items: Center;
     overflow: hidden;
     .el-dialog {
         margin: 1px auto !important;
         height: 90%;
         overflow: hidden;
         .el-dialog__body {
             position: absolute;
             left: 0;
             top: 54px;
             bottom: 0;
             right: 0;
             padding: 0;
             z-index: 1;
             overflow: hidden;
             overflow-y: auto;
         }
     }
 }

